<template>
  <header class="ya-te-header">
    <div class="co-container-md navbar min-h-full py-0 pl-4 pr-0 lg:px-4">
      <img
        class="ya-te-header-logo w-full"
        src="~/assets/images/YaTe/YaTeCommon/Logo/logo_ly.svg"
        alt="LINEヤフーテックアカデミー"
        width="326"
        height="70"
      >
    </div>
  </header>
</template>

<style scoped>
.ya-te-header {
  z-index: 1030;
  width: 100%;
  height: 3.4375rem;
  background-color: #fff;
  @media (min-width: 768px) {
    height: 5rem;
  }
}

.ya-te-header-logo {
  width: 12.5rem;
  @media (min-width: 768px) {
    width: 20.375rem;
  }
}
</style>
