<template>
  <div>
    <TheYaTeCommonHeaderMdNolink />
    <main>
      <slot />
    </main>
    <TheYaTeCommonFooterSimple />
  </div>
</template>

<script setup lang="ts">
import TheYaTeCommonHeaderMdNolink from '~/components/YaTe/YaTeCommon/Header/TheYaTeCommonHeaderMdNolink.vue'
import TheYaTeCommonFooterSimple from '~/components/YaTe/YaTeCommon/Footer/TheYaTeCommonFooterSimple.vue'

useHead({
  htmlAttrs: {
    class: '!bg-black',
    'data-theme': 'lineyahoo',
  },
  bodyAttrs: {
    class: 'bg-white',
  },
  link: [
    {
      hid: "favicon",
      rel: 'icon',
      type: 'image/x-icon',
      href: '/favicon-yate.ico',
    },
  ],
})
</script>
